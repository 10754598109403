import React, { useState, useEffect } from 'react';
import { Countdown } from '../countdown/countdown';

export const Banner = () => {
	return (
		<div id='home'>
			<section>
				<img src='/image/bg1.webp' alt='' className='hero-banner-img'></img>
			</section>
		</div>
	);
};

{
	/* <div className='container text-center'>
					<div className='around col-7'>
						<div data-aos='fade-down-right'>
							{' '}
							<p>08 - 11 April 2021, Hammamet City </p>
							<h1>CSTC 2021</h1>
						</div>
						<div data-aos='fade-up-left'>
							<Countdown></Countdown>
						</div>
					</div>
				</div> */
}
